body {
  /* dark navy blue background */
  background-color: #112C5F ;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.topnav {
  overflow:hidden;
  background-color: #F5C773
}


.topnav a{
  color: #38568C; 
  padding: 14px 16px;
  text-decoration: none; 
  font-size: 17px;
  display: inline-block; 
}

.topnav #links a{
  color: #38568C; 
  padding: 14px 16px;
  text-decoration: none; 
  font-size: 17px;
  display: block; 
}

.topnav a.icon {
  display: inline-block;
  position: absolute; 
  right: 0;
  top: 0; 
}

.topnav #title:hover{
  color: #fff; 
}

.topnav #links a:hover{
  background-color: #849AC4;
  color: #fff; 
}

.topnav a.icon:hover{
  background-color: #849AC4;
  color: #fff; 
}


.not-clicked{
  display: none; 
}

.clicked{
  display: block; 
}

.page-name{
  text-transform: lowercase;
  font-weight: lighter;
  font-size: 20px;
}


