

/* table styling with dark background and light text with borders */
.note-table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  background-color: #333;
  color: #fff;
}

/* center table across 80% of page */
.note-table {
  margin: 0 auto;
  width: 80%;
}

/* table has lines between rows */
.note-table tr {
  border-bottom: 1px solid #ddd;
}

/* table header has inverted style from table body */
.note-table th {
  background-color: #444;
  color: #fff;
}