body{
  text-align: center;
}

.pages-list li{
  margin: 0 10px;
  text-decoration: none;
  list-style: none;
  margin: 5px 0 0 0;

}

/* links have no decoration and are white in color */
.pages-list li a{
  color: white;
  text-decoration: none;
}

.pages-list ul{
  padding: 0; 
}

.pages-list a:hover{
  color: #D3A247;
  text-decoration: none;
}

