.bpm-button{
  /* centered and width of 50% */
  display: inline-block;
  width: 50%;
  margin: 0 auto;
  height: 100%;

  line-height: 400%;
  background: #aaaaaa; 
  color: #eeeeee;
  font-size: 20px;
  outline: none;
  border: none;
  background-color: #9cb3a8;
  transition: background-color 0.25s ease-out;
}

.bpm-button:active {
  background-color: rgb(255, 130, 80);
}

