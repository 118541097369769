/* dark blue background with lighter text and centered layout of 3 columns*/

/* lists without bullets */
/* prefer helvetica font for body but with fallback */
body {
  font-family: 'Helvetica','Arial', sans-serif;
  font-weight: lighter;
  line-height: 1.5;
}

/* As hex codes */

.color-primary-0 { color: #38568C }	/* Main Primary color */
.color-primary-1 { color: #849AC4 }
.color-primary-2 { color: #5570A3 }
.color-primary-3 { color: #234178 }
.color-primary-4 { color: #112C5F }

.color-complement-0 { color: #D3A247 }	/* Main Complement color */
.color-complement-1 { color: #FFDFA3 }
.color-complement-2 { color: #F5C773 }
.color-complement-3 { color: #B58326 }
.color-complement-4 { color: #8F610D }



/* As RGBa codes */

.rgba-primary-0 { color: rgba( 56, 86,140,1) }	/* Main Primary color */
.rgba-primary-1 { color: rgba(132,154,196,1) }
.rgba-primary-2 { color: rgba( 85,112,163,1) }
.rgba-primary-3 { color: rgba( 35, 65,120,1) }
.rgba-primary-4 { color: rgba( 17, 44, 95,1) }

.rgba-complement-0 { color: rgba(211,162, 71,1) }	/* Main Complement color */
.rgba-complement-1 { color: rgba(255,223,163,1) }
.rgba-complement-2 { color: rgba(245,199,115,1) }
.rgba-complement-3 { color: rgba(181,131, 38,1) }
.rgba-complement-4 { color: rgba(143, 97, 13,1) }
